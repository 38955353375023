import clsx from "clsx";
import type { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { formatDate } from "~/utils/misc";
import { getImageProps } from "~/utils/images";
import { Paragraph } from "~/components/ui/typography";
import { Card } from "~/components/ui/card";
import { Image } from "~/components/ui/image";
import type { Language } from "~/utils/language";

export interface Author {
	imageBuilder: ImageUrlBuilder | null | undefined;
	name: string;
	shortName?: string;
}

export interface Category {
	title: string | null;
	slug: string;
}

export interface Post {
	id: string;
	slug: string;
	title: string;
	description?: string | null;
	authors?: Author[];
	date?: string | null;
	coverImgBuilder: ImageUrlBuilder | null;
	categories?: Category[] | null;
}

interface Props {
	post: Post;
	emphasized?: boolean;
	variant: BlogCardVariant;
	lang?: Language;
}

export type BlogCardVariant = "emphasized" | "simplified" | "default";

export function BlogCard({ post, variant = "default", lang }: Props) {
	const { title, date, coverImgBuilder, authors, description } = post;

	const simplified = variant === "simplified";
	const cardPadding = variant === "emphasized" ? "!px-5 lg:!px-7" : "!px-5";

	const imgCoverProps =
		variant === "emphasized"
			? getImageProps(coverImgBuilder, {
					widths: [400, 600, 800, 1000],
					sizes: ["(min-width:1024px) 70vw", "100vw"],
				})
			: getImageProps(coverImgBuilder, {
					widths: [400, 600],
					sizes: ["(min-width:768px) 40vw", "(min-width:1024px) 30vw", "100vw"],
				});

	function renderAuthorAvatars(size: "small" | "default" = "default") {
		const smallSize = size === "small";

		if (!authors || (authors && authors.length === 0)) {
			return null;
		}

		// To manage the z-index of authors without setting a separate z-index on each one
		// I reverse the author list then use flex-row-reverse to make it looks like the right order
		return authors?.reverse().map((author, index) => {
			const isLastIndex = index === authors.length - 1;
			return (
				<div
					key={author.name}
					className={clsx(
						"border-stroke flex items-center justify-center rounded-full border",
						{ "h-9 w-9": !smallSize },
						{ "h-6 w-6": smallSize },
						{ "-ml-5": !isLastIndex && !smallSize },
						{ "-ml-3": !isLastIndex && smallSize }
					)}
				>
					<Image
						className={clsx(
							"rounded-full object-cover",
							{ "h-[46px] w-[46px]": !smallSize },
							{ "h-[22px] w-[22px]": smallSize }
						)}
						{...getImageProps(author.imageBuilder?.width(100)?.height(100))}
					/>
				</div>
			);
		});
	}

	function renderAuthorNames() {
		return (
			<Paragraph size="body-small" className="!font-medium">
				{authors
					?.map((author) => {
						if (author?.shortName) {
							return author.shortName;
						}

						const [firstName] = author.name.split(" ");
						return firstName;
					})
					.join(" & ")}
			</Paragraph>
		);
	}

	return (
		<Card href={post.slug} trackingPosition="blog">
			<Card.Cover imgProps={imgCoverProps} />
			{date ? (
				<Card.Outline
					className={cardPadding}
					extra={
						simplified && (
							<div className="flex flex-row-reverse">
								{renderAuthorAvatars("small")}
							</div>
						)
					}
				>
					{`${formatDate(date, lang)} ${simplified ? " | By" : ""}`}
				</Card.Outline>
			) : null}
			<Card.Content className={cardPadding}>
				<Card.Title>{title}</Card.Title>
				{!simplified && description ? (
					<Card.Subtitle>{description}</Card.Subtitle>
				) : null}
			</Card.Content>
			{!simplified ? (
				<Card.Extra className={cardPadding}>
					<div className="isolate mt-auto flex items-center gap-5">
						<div className="flex flex-row-reverse">{renderAuthorAvatars()}</div>
						<div>{renderAuthorNames()}</div>
					</div>
				</Card.Extra>
			) : null}
		</Card>
	);
}
